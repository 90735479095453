import React from 'react';
import {Box} from "@mui/material";

const Main = () => {
    return (
        <Box className='main'>
MAIN
        </Box>
    );
};

export default Main;